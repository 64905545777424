import { DependencyList, useEffect } from 'react';

export const useDebouncedEffect = (
  fn: () => void,
  deps: DependencyList = [],
  time = 100,
) => {
  useEffect(() => {
    const handler = setTimeout(fn, time);

    return () => clearTimeout(handler);
  }, deps);
};
