import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  faPeopleGroup,
  faGear,
  faLocationPin,
  faLeaf,
  faImages,
} from '@fortawesome/pro-light-svg-icons';
import SettingMenu, {
  SettingMenuItem,
} from 'components/common/menu/settingMenu';
import { AppDialogContent } from 'styled/modal';
import OrgAccountSettings from 'components/setting/orgSetting/accountSettings';
import MemberSettings, {
  MemberSettingsAPI,
} from 'components/setting/orgSetting/memberSetttings';
import { useGetOrganizationDetail } from 'components/common/apis/orgAPI';
import { CircularProgress, Stack } from '@mui/material';
import { ModalContext } from 'provider/modalProvider';
import moment from 'moment';
import { DATE_FORMAT } from 'components/utils/constants';
import _ from 'lodash';
import Title from 'components/common/formComponent/subHeader';
import SiteSettings from 'components/setting/orgSetting/siteSettings';
import { useUpdateMapInfoPanelContent } from 'components/common/hook/map';
import { Country } from 'components/map/constants';
import SiteSetting from 'components/setting/siteSetting';
import PlantedAreasManagement from 'components/setting/orgSetting/plantedAreas';
import { useGetUserOrgMembership } from 'components/common/apis/userAPI';
import SitePhotoSetting from 'components/setting/orgSetting/sitePhotoSetting';
import { OrganizationDTO } from 'hnpmap-api';
import { EnablePhotoSharingButton } from 'components/setting/orgSetting/sitePhotoSetting/enablePhotoSharing/enablePhotoSharingButton';
import { ActionButton } from 'components/setting/orgSetting/styles';
import { InviteMemberButton } from 'components/setting/orgSetting/memberSetttings/inviteMemberModal/inviteMemberButton';
import { useUserInfo } from 'provider/userProvider';

export enum SettingView {
  ManageAccount = 'ManageAccount',
  ManageMember = 'ManageMember',
  ManageSite = 'ManageSite',
  ManageAreaPlanted = 'ManageAreaPlanted',
  ManagePhoto = 'ManagePhoto',
}
type OrgSettingsProps = {
  orgId?: number;
  initialView?: SettingView;
  onReload?: () => void;
};
type OrgSettingItem = SettingMenuItem<SettingView> & {
  view: JSX.Element;
  subTitleSlot?: JSX.Element;
};
export default function OrgSettings({
  orgId,
  initialView = SettingView.ManageAccount,
  onReload,
}: OrgSettingsProps) {
  const [t] = useTranslation();
  const { updateSubTitle } = useContext(ModalContext);
  const handleSiteUpdated = useUpdateMapInfoPanelContent();
  const { showModal } = useContext(ModalContext);
  const { userInfo } = useUserInfo();
  const [{ loading: loadingOrganizationDetail }, getOrganizationDetail] =
    useGetOrganizationDetail();
  const [
    { data: userMembership, loading: loadingMembership },
    getUserOrgMembership,
  ] = useGetUserOrgMembership();
  const memberSettingsRef = useRef<MemberSettingsAPI>(null);

  const [organization, setOrganization] = useState<
    OrganizationDTO | undefined
  >();
  const [view, setView] = useState<SettingView>(initialView);

  const changeView = (setting: SettingView) => {
    setView(setting);
  };

  const updateOrganization = (newData: OrganizationDTO) => {
    setOrganization((prevData) => ({ ...prevData, ...newData }));
  };

  const isLoading = orgId
    ? _.isNil(organization) || loadingOrganizationDetail || loadingMembership
    : false;

  useEffect(() => {
    if (orgId) {
      getUserOrgMembership({ urlParams: { orgId } });
      getOrganizationDetail({ params: { organizationId: orgId } }).then(
        (res) => res?.data && setOrganization(res.data),
      );
    }
  }, [orgId]);

  useEffect(() => {
    if (organization?.id) {
      updateSubTitle(
        t('on_the_map_since', {
          mmmYYYY: moment(organization.createdDate).format(
            DATE_FORMAT.YEAR_MONTH,
          ),
        }),
      );
    }
  }, [organization?.id]);

  const openSiteSetting = (orgId?: number) => {
    const initialValues = {
      primary: false,
      searchValue: '',
      locationName: '',
      country: userInfo?.country || Country.US,
      stateCode: '',
      city: '',
      postcode: '',
      areaPlanted: [],
      orgId,
    };

    showModal({
      content: (
        <SiteSetting
          initialValues={initialValues}
          onReload={() => {
            handleSiteUpdated();
            onReload?.();
          }}
        />
      ),
      title: t('site_settings'),
      closeBtn: false,
      width: 460,
    });
  };

  const orgSettings = useMemo<Array<OrgSettingItem>>(() => {
    return [
      {
        label: t('account'),
        value: SettingView.ManageAccount,
        icon: faGear,
        view: (
          <OrgAccountSettings
            organization={organization}
            userOrganizationRole={userMembership?.role}
            onReload={onReload}
          />
        ),
        showSubmitButton: true,
      },
      ...(organization
        ? [
            {
              label: t('members'),
              value: SettingView.ManageMember,
              icon: faPeopleGroup,
              view: (
                <MemberSettings
                  ref={memberSettingsRef}
                  organization={organization}
                />
              ),
              subTitleSlot: (
                <InviteMemberButton
                  orgId={organization.id as number}
                  userOrgRole={userMembership?.role}
                  onSuccess={() => memberSettingsRef.current?.refetchData()}
                />
              ),
            },
            {
              label: t('sites'),
              value: SettingView.ManageSite,
              icon: faLocationPin,
              view: <SiteSettings organization={organization} />,
              subTitleSlot: (
                <ActionButton onClick={() => openSiteSetting(organization?.id)}>
                  {t('add_site')}
                </ActionButton>
              ),
            },
            {
              label: t('planted_areas'),
              value: SettingView.ManageAreaPlanted,
              icon: faLeaf,
              view: <PlantedAreasManagement organization={organization} />,
              subTitleSlot: (
                <ActionButton
                  onClick={() => changeView(SettingView.ManageSite)}
                >
                  {t('manage_sites')}
                </ActionButton>
              ),
            },
            {
              label: t('photos'),
              value: SettingView.ManagePhoto,
              icon: faImages,
              view: (
                <SitePhotoSetting
                  organization={organization}
                  onUpdateOrganization={updateOrganization}
                  changeSettingView={changeView}
                />
              ),
              subTitleSlot: (
                <EnablePhotoSharingButton
                  organization={organization}
                  onUpdateOrganization={updateOrganization}
                />
              ),
            },
          ]
        : []),
    ];
  }, [organization]);

  const currentSetting = useMemo<OrgSettingItem | undefined>(
    () => orgSettings.find((item) => item.value === view),
    [view, organization],
  );

  return (
    <AppDialogContent
      sx={{
        '.admin-page-container': {
          pt: 0,
          px: '1.5vmin',
        },
      }}
    >
      {isLoading ? (
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ m: '5rem 2rem' }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Title
            title={
              <SettingMenu
                options={orgSettings}
                value={view}
                readonly={!orgId}
                onSelect={changeView}
              />
            }
            secondaryTitle={currentSetting?.subTitleSlot}
            sx={{
              px: '1rem',
            }}
          ></Title>
          {currentSetting?.view}
        </>
      )}
    </AppDialogContent>
  );
}
