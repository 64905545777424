import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SxProps,
  Theme,
  Typography,
  Link,
  styled,
  Pagination,
  PaginationItem,
} from '@mui/material';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import COLORS from 'styled/colors';
import { setSxProps } from 'styled/utils';

export const CellText = ({
  sx,
  text,
  onClick,
}: {
  sx?: SxProps<Theme>;
  text: string | number;
  onClick?: () => void;
}) => {
  if (onClick) {
    return (
      <Link
        sx={[
          {
            color: COLORS.DarkCharcoal,
            fontFamily: 'Roboto',
            fontSize: '0.875rem',
            textDecoration: 'unset',
            fontWeight: 700,
            lineHeight: '1.75rem',
          },
          ...setSxProps(sx),
        ]}
        component="button"
        onClick={onClick}
      >
        {text}
      </Link>
    );
  }
  return (
    <Typography
      sx={[
        {
          color: COLORS.DarkCharcoal,
          fontFamily: 'Roboto',
          fontSize: '0.875rem',
          lineHeight: '1.75rem',
        },
        ...setSxProps(sx),
      ]}
      component="span"
    >
      {text}
    </Typography>
  );
};

export const StyledPagination = styled(
  (props) => (
    <Pagination
      {...props}
      renderItem={(item) => {
        return (
          <PaginationItem
            slots={{
              previous: () => (
                <FontAwesomeIcon icon={faAngleLeft} fontSize={'1rem'} />
              ),
              next: () => (
                <FontAwesomeIcon icon={faAngleRight} fontSize={'1rem'} />
              ),
            }}
            {...item}
          />
        );
      }}
    />
  ),
  { shouldForwardProp: () => true },
)(({ theme }) => ({
  '.MuiPaginationItem-root': {
    maxHeight: '1.875rem',
    minWidth: '1.875rem',
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
  },
})) as typeof Pagination;
