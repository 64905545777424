import { debounce } from '@mui/material';
import {
  AsyncOptionsConfig,
  SelectOption,
} from 'components/admin/table/filter/types';
import { useAPI } from 'components/common/hook/useApi';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

const QUERY_WAIT_TIME = 700;
export default function useAsyncFetchOptions({
  asyncOptionsConfigs,
  filterValues,
}: {
  asyncOptionsConfigs?: AsyncOptionsConfig;
  filterValues: FieldValues;
}): [
  { data: SelectOption[] | undefined; loading: boolean },
  (input: string) => void,
] {
  const [options, setOptions] = useState<SelectOption[] | undefined>();
  const [{ loading }, fetchOptions] = useAPI(
    { url: asyncOptionsConfigs?.url },
    { manual: true, autoCancel: false },
  );
  const requestParams = useMemo(
    () =>
      typeof asyncOptionsConfigs?.params === 'function'
        ? asyncOptionsConfigs?.params(filterValues)
        : asyncOptionsConfigs?.params,
    [filterValues],
  );

  const handleAsyncOptionData = (data: any[]) => {
    const newOptions = asyncOptionsConfigs?.format?.(data) || data;
    setOptions(newOptions);
  };

  const executeFetchOptions = (params?: Record<string, any>) => {
    fetchOptions({
      params,
    })
      .then((res) => {
        if (res?.data) {
          handleAsyncOptionData(res.data);
        }
      })
      .catch((e) => e);
  };

  useEffect(() => {
    if (asyncOptionsConfigs?.fetchMode === 'initial') {
      executeFetchOptions(requestParams);
    }
  }, [asyncOptionsConfigs?.fetchMode, requestParams]);

  const requestFetchOptions = useMemo(
    () =>
      debounce((input: string) => {
        if (
          asyncOptionsConfigs?.fetchMode === 'input' &&
          asyncOptionsConfigs?.param
        ) {
          if (input?.length > 0) {
            const params = asyncOptionsConfigs?.param
              ? { [asyncOptionsConfigs.param]: input }
              : undefined;
            executeFetchOptions(params);
          } else {
            setOptions([]);
          }
        }
      }, QUERY_WAIT_TIME),
    [],
  );

  return [{ data: options, loading }, requestFetchOptions];
}
