import { PageDTO } from 'components/admin/adminTable';
import { useAPI } from 'components/common/hook/useApi';
import { useAfterLoginSuccess } from 'components/common/hook/useAuth';
import {
  StatesOfCountryItem,
  CountryDTO,
  StateDTO,
  CountryConfigDTO,
} from 'hnpmap-api';

const COUNTRY_BASE_PATH = '/api/country';
const UPDATE_COUNTRY_API = `${COUNTRY_BASE_PATH}/{{countryId}}`;
const GET_COUNTRY_API = `${COUNTRY_BASE_PATH}/{{countryId}}`;
const GET_COUNTRY_STATE_API = `${COUNTRY_BASE_PATH}/{{countryId}}/states/{{stateId}}`;
const UPDATE_COUNTRY_STATE_API = GET_COUNTRY_STATE_API;
const GET_STATES_OF_COUNTRY_API = `${COUNTRY_BASE_PATH}/list-states`;
export const EXPORT_STATES_OF_COUNTRY_API = `${GET_STATES_OF_COUNTRY_API}/csv`;

export const useGetStatesOfCountry = () => {
  return useAPI<PageDTO<StatesOfCountryItem>>(
    {
      url: GET_STATES_OF_COUNTRY_API,
      method: 'get',
    },
    { manual: true },
  );
};

export const useGetCountry = () => {
  return useAPI<CountryDTO>(
    {
      url: GET_COUNTRY_API,
      method: 'get',
    },
    { manual: true },
  );
};

export const useUpdateCountry = () => {
  return useAPI<CountryDTO>(
    {
      url: UPDATE_COUNTRY_API,
      method: 'put',
    },
    { manual: true },
  );
};

export const useGetCountryState = () => {
  return useAPI<StateDTO>(
    {
      url: GET_COUNTRY_STATE_API,
      method: 'get',
    },
    { manual: true },
  );
};

export const useUpdateCountryState = () => {
  return useAPI<StateDTO>(
    {
      url: UPDATE_COUNTRY_STATE_API,
      method: 'put',
    },
    { manual: true },
  );
};

export const useGetCountries = () => {
  const [{ data }, request] = useAPI<CountryConfigDTO[]>(
    {
      url: COUNTRY_BASE_PATH,
      method: 'get',
    },
    { manual: true },
  );

  useAfterLoginSuccess(() => {
    request();
  });
  return data;
};
