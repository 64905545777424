import { Box, DialogContent } from '@mui/material';
import AdminTable, {
  AdminTableAPI,
  AdminTableProps,
} from 'components/admin/adminTable';
import { RowData } from 'components/admin/table/types';
import { useTranslation } from 'react-i18next';
import { StyledButton } from 'styled/button';
import { StyledModalActions } from 'styled/modalAction';

export default function ManageTable<
  TData extends RowData,
  TFilters extends Record<string, any>,
>({
  tableRef,
  onClose,
  ...props
}: Omit<AdminTableProps<TData, TFilters>, 'children'> & {
  tableRef: React.ForwardedRef<AdminTableAPI<TData, TFilters>>;
  onClose: () => void;
}) {
  const [t] = useTranslation();

  return (
    <>
      <DialogContent sx={{ p: '1rem 1rem 0' }}>
        <AdminTable<TData, TFilters>
          disableRowPerPageSelect
          {...props}
          ref={tableRef}
          numRowsLoading={props.pageData?.data?.length}
          styles={{
            tableContainerSx: {
              minHeight: 'unset',
              height: '26.8rem',
              table: {
                'th:first-child,td:first-child': {
                  pl: '22px',
                },
                'th:last-child,td:last-child': {
                  pr: '15px',
                },
                'th,td': {
                  px: '5px',
                },
                th: {
                  whiteSpace: 'nowrap',
                },
              },
            },
          }}
        >
          {({ Filters, Table }) => {
            return (
              <>
                <Box sx={{ mb: '0.9375rem' }}>{Filters}</Box>
                {Table}
              </>
            );
          }}
        </AdminTable>
      </DialogContent>
      <StyledModalActions>
        <StyledButton variant="outlined" type="reset" onClick={onClose}>
          {t('cancel')}
        </StyledButton>
      </StyledModalActions>
    </>
  );
}
