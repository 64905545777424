import React, { useContext } from 'react';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import Select from 'components/common/input/select';
import { AppConfigContext } from 'provider/appConfigProvider';
import { useTranslation } from 'react-i18next';
import {
  Control,
  Controller,
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';
import { Placeholder } from 'styled/typography';

export const OrgCategorySelect = (props: {
  id: string;
  label: string;
  name: string;
  defaultValue?: number | '';
  error?: boolean;
  helperText?: string;
  control?: Control<any>;
  placeholder: string;
  setValue: UseFormSetValue<FieldValues>;
}) => {
  const {
    id,
    label,
    name,
    defaultValue,
    error,
    helperText,
    control,
    placeholder,
    setValue,
  } = props;

  const [t] = useTranslation();
  const { orgCategories } = useContext(AppConfigContext);

  const renderValue = (value: unknown) => {
    return value ? (
      <>
        {t(
          `org_categories.${orgCategories?.find((t) => t.id === value)?.name}`,
        )}
      </>
    ) : (
      <Placeholder>{placeholder}</Placeholder>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { ref: selectRef, onBlur, onChange } = field;
        return (
          <Select
            onBlur={onBlur}
            onClose={onBlur}
            onChange={(event: SelectChangeEvent<unknown>) => {
              if (event.target.value !== field.value) {
                const category = orgCategories?.find(
                  (t) => t.id === event.target.value,
                );
                if (category?.subCategories?.length == 1) {
                  setValue('subOrgCategoryId', category.subCategories[0].id, {
                    shouldValidate: true,
                  });
                } else {
                  setValue('subOrgCategoryId', undefined);
                }
              }
              onChange(event);
            }}
            defaultValue={defaultValue || ''}
            inputRef={selectRef}
            id={id}
            label={label}
            error={error}
            helperText={helperText}
            displayEmpty
            renderValue={renderValue}
          >
            {orgCategories?.map((opt) => (
              <MenuItem key={opt.id} value={opt.id}>
                {t(`org_categories.${opt.name}`)}
              </MenuItem>
            ))}
          </Select>
        );
      }}
    />
  );
};
