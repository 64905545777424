import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, AlertTitle, Box, SxProps, Theme } from '@mui/material';
import { BaseSyntheticEvent, useEffect, useRef } from 'react';
import {
  DefaultValues,
  FieldValues,
  UseFormReturn,
  ValidationMode,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useApiErrorMessage } from '../hook/useApi';

const FORM_ERROR = 'FORM_ERROR';

type FormProps<
  TFormValues extends FieldValues,
  TExtraValues = Record<string, any>,
> = {
  defaultValues?: DefaultValues<TFormValues>;
  validationSchema?: yup.ObjectSchema<yup.AnyObject>;
  validationMode?: keyof ValidationMode;
  children: (
    formProps: UseFormReturn<TFormValues> & {
      setFormError: (errMsg: string) => void;
      setExtraValues: (key: keyof TExtraValues, value: any) => void;
    },
  ) => JSX.Element | JSX.Element[] | null;
  onSubmit: (
    data: TFormValues,
    extraValues: TExtraValues,
    event?: React.BaseSyntheticEvent,
  ) => unknown | Promise<unknown>;
  errorMessage?: string | null;
  sx?: SxProps<Theme>;
};
export default function Form<
  TFormValues extends FieldValues,
  TExtraValues = Record<string, any>,
>({
  defaultValues,
  validationMode = 'onSubmit',
  validationSchema,
  sx,
  onSubmit,
  errorMessage: errorMessageProp,
  children,
}: FormProps<TFormValues, TExtraValues>) {
  const [t] = useTranslation();
  const { getApiErrorMessage } = useApiErrorMessage();
  const methods = useForm<TFormValues>({
    defaultValues,
    resolver: validationSchema
      ? (yupResolver(validationSchema) as any)
      : undefined,
    mode: validationMode,
  });
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;
  const changedValues = useWatch({ control });
  const extraValues = useRef<TExtraValues>({} as TExtraValues);

  const setFormError = (message: string) => {
    setError(FORM_ERROR as any, {
      message,
    });
  };

  const clearFormError = () => {
    clearErrors(FORM_ERROR as any);
  };

  useEffect(() => {
    clearFormError();
  }, [changedValues]);

  useEffect(() => {
    if (errorMessageProp) {
      setFormError(errorMessageProp);
    } else {
      clearFormError();
    }
  }, [errorMessageProp]);

  const handleFormSubmit = async (
    values: TFormValues,
    e?: BaseSyntheticEvent,
  ) => {
    try {
      await onSubmit(values, extraValues.current, e);
    } catch (error: any) {
      console.error(error);
      setError(FORM_ERROR as any, {
        message: getApiErrorMessage(error?.message),
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)} sx={sx}>
      {!!errors[FORM_ERROR] && (
        <Alert severity="error">
          <AlertTitle>{t('server_error')}</AlertTitle>
          {errors[FORM_ERROR].message as string}
        </Alert>
      )}
      {children({
        ...methods,
        setFormError,
        setExtraValues: (key: keyof TExtraValues, value: any) => {
          extraValues.current = { ...extraValues.current, [key]: value };
        },
      })}
    </Box>
  );
}
