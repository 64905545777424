import { AxiosRequestConfig } from 'axios';
import type { UseAPIOptions } from 'components/common/hook/useApi/types';
import { MINUTE_IN_MS } from 'components/utils/constants';
import { dequal as deepEqual } from 'dequal/lite';
import _ from 'lodash';
import { useRef } from 'react';

export function configToObject(config: AxiosRequestConfig | string) {
  if (typeof config === 'string') {
    return {
      url: config,
    };
  }

  return Object.assign({}, config);
}

export function useDeepCompareMemoize(value: any) {
  const ref = useRef();
  const signalRef = useRef(0);

  if (!deepEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  return [signalRef.current];
}

export function newAbortController(timeoutMs: number): AbortController {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeoutMs || 0);

  return abortController;
}

export const UseApiOption = {
  cacheInMinute: ({
    minute,
    ...opt
  }: { minute: number } & Omit<
    Partial<UseAPIOptions>,
    'cacheTTL' | 'caching'
  >) => {
    const cacheTTL = MINUTE_IN_MS * (minute || 1);
    return _.merge(
      { caching: true, manual: true },
      _.omitBy({ ...opt, cacheTTL }, _.isUndefined),
    );
  },
};
