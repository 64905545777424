import React from 'react';
import { Box, styled } from '@mui/material';
import TextField from 'components/common/input/textfield';
import CollapseComponent from 'components/common/formComponent/collapse';
import { useTranslation } from 'react-i18next';
import { UserTypeSelector } from 'components/setting/mySetting/userTypeSelector';
import { FieldValues, Path } from 'react-hook-form';
import { FormFieldRegister } from 'components/common/form/formField';

const AccountBoxFormStyle = styled(Box)(() => ({
  padding: '0.5rem 1rem 0.5rem 1rem',
}));

export type AccountFormFields = {
  userTypeId: number;
  firstName: string;
  lastName: string;
  email: string;
};

export const AccountForm = <TFormValues extends FieldValues>(
  props: {
    collapseDisabled: boolean;
    isReadonlyEmail?: boolean;
  } & FormFieldRegister<TFormValues>,
) => {
  const [t] = useTranslation();
  const { register, getValues, errors, collapseDisabled, isReadonlyEmail } =
    props;

  return (
    <CollapseComponent title={t('account')} disabled={collapseDisabled}>
      <AccountBoxFormStyle>
        <UserTypeSelector
          {...register('userTypeId' as Path<TFormValues>)}
          id="userTypeId"
          label={t('category')}
          value={getValues('userTypeId' as Path<TFormValues>)}
          error={!!errors['userTypeId']}
          helperText={errors['userTypeId']?.message?.toString()}
          defaultValue={1}
        />
        <TextField
          {...register('firstName' as Path<TFormValues>)}
          defaultValue={getValues('firstName' as Path<TFormValues>)}
          id="firstName"
          label={t('first_name')}
          error={!!errors['firstName']}
          helperText={errors['firstName']?.message?.toString()}
        />
        <TextField
          {...register('lastName' as Path<TFormValues>)}
          defaultValue={getValues('lastName' as Path<TFormValues>)}
          id="lastName"
          label={t('last_name')}
          error={!!errors['lastName']}
          helperText={errors['lastName']?.message?.toString()}
        />
        <TextField
          {...register('email' as Path<TFormValues>)}
          defaultValue={getValues('email' as Path<TFormValues>)}
          id="email"
          label="Email"
          error={!!errors['email']}
          helperText={errors['email']?.message?.toString()}
          InputProps={{ readOnly: isReadonlyEmail }}
        />
      </AccountBoxFormStyle>
    </CollapseComponent>
  );
};
