import { SitePlantingDetailDTO } from 'hnpmap-api';
import {
  faAngleLeft,
  faAngleRight,
  faArrowDownLeftAndArrowUpRightToCenter,
  faCircleExclamation,
  faExpand,
  faImages,
  faPause,
  faPlay,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Skeleton, styled } from '@mui/material';
import { useGetSitePhotos } from 'components/common/apis/mapAPI';
import { MouseEventHandler, useEffect, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import COLORS from 'styled/colors';
import _ from 'lodash';
import FavoriteIcon from 'components/setting/siteSetting/sitePhoto/favoriteIcon';
import { ImageLoader } from 'components/common/org/imageLoader';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'components/common/hook/browser';

export const ThumbnailContainer = styled(Box)(() => ({
  aspectRatio: '1.83',
  overflow: 'hidden',
  boxSizing: 'border-box',
}));

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ImageGalleryEmpty = () => {
  const [t] = useTranslation();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ p: '5rem 7.5rem' }}
    >
      <Grid
        container
        direction="column"
        sx={{
          borderRadius: '0.625rem',
          border: `1px dashed ${COLORS.QuickSilver}`,
          textAlign: 'center',
          height: '12rem',
        }}
        justifyContent="center"
      >
        <Box>
          <FontAwesomeIcon
            fontSize="1.5rem"
            icon={faImages}
            color={COLORS.QuickSilver}
          />
        </Box>
        <Box sx={{ p: '1rem 0rem' }}>{t('no_photos_on_this_site')}</Box>
      </Grid>
    </Grid>
  );
};

const ImageGalleryLoading = () => (
  <Grid container columns={{ xs: 11 }}>
    <Grid item xs={2}>
      {Array.from(Array(4)).map((_, index) => (
        <Item key={index} sx={{ width: '100%', aspectRatio: '1.83' }}>
          <Skeleton variant="rectangular" sx={{ height: '100%' }} />
        </Item>
      ))}
    </Grid>
    <Grid item xs={9}>
      <Item sx={{ width: '100%', aspectRatio: '1.83' }}>
        <Skeleton variant="rectangular" sx={{ height: '100%' }} />
      </Item>
    </Grid>
  </Grid>
);

const renderLeftNav = (
  onClick: MouseEventHandler<HTMLElement>,
  disabled: boolean,
) => (
  <button
    type="button"
    className="image-gallery-icon image-gallery-left-nav"
    disabled={disabled}
    onClick={onClick}
  >
    <FontAwesomeIcon fontSize="2.5rem" icon={faAngleLeft} />
  </button>
);

const renderRightNav = (
  onClick: MouseEventHandler<HTMLElement>,
  disabled: boolean,
) => (
  <button
    type="button"
    className="image-gallery-icon image-gallery-right-nav"
    disabled={disabled}
    onClick={onClick}
  >
    <FontAwesomeIcon fontSize="2.5rem" icon={faAngleRight} />
  </button>
);

const renderPlayPauseButton = (
  onClick: MouseEventHandler<HTMLElement>,
  isPlaying: boolean,
) => (
  <button
    type="button"
    className="image-gallery-icon image-gallery-play-button"
    onClick={onClick}
  >
    <FontAwesomeIcon fontSize="1.25rem" icon={isPlaying ? faPause : faPlay} />
  </button>
);
export const renderFullscreenButton = (
  onClick: MouseEventHandler<HTMLElement>,
  isFullscreen: boolean,
) => (
  <button
    type="button"
    className="image-gallery-icon image-gallery-fullscreen-button"
    onClick={onClick}
  >
    <FontAwesomeIcon
      fontSize="1.25rem"
      icon={isFullscreen ? faArrowDownLeftAndArrowUpRightToCenter : faExpand}
    />
  </button>
);

export const Gallery = styled(Box)(({ theme }) => ({
  padding: '0.31rem',
  '.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left': {
    margin: '0 5px 0 0',
    width: '18%',
    '.image-gallery-thumbnail': {
      width: '100%',
    },
  },
  '.fullscreen': {
    '.image-gallery-slide-wrapper.image-gallery-thumbnails-left': {
      width: '100%',
    },
  },
  '.image-gallery-slide-wrapper.image-gallery-thumbnails-left': {
    width: 'calc(82% - 5px)',
    height: '100%',
  },
  '.image-gallery-icon:hover': {
    color: `${theme.palette.primary.main}`,
  },
  '.image-gallery-icon:focus': {
    outline: 'none',
  },
  '.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover':
    {
      border: `3px solid ${theme.palette.primary.main}`,
    },
  '.image-gallery-bullets': {
    '.image-gallery-bullet:hover, .image-gallery-bullet:focus': {
      background: `${theme.palette.primary.main}`,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

const renderThumbInner = (item: ReactImageGalleryItem) => (
  <ThumbnailContainer>
    <span className="image-gallery-thumbnail-inner">
      {item.thumbnailClass === 'favorite' ? (
        <FavoriteIcon
          className="favorite-thumbnail"
          sx={{
            position: 'absolute',
            right: {
              xs: '-0.25rem',
              sm: '0rem',
            },
            top: {
              xs: '-0.25rem',
              sm: '0rem',
            },
            fontSize: {
              xs: '0.75rem',
              sm: '1rem',
              md: '1.5rem',
            },
          }}
          disabled
        />
      ) : null}
      <ImageLoader
        className="image-gallery-thumbnail-image"
        src={item.thumbnail}
        failedElement={
          <FontAwesomeIcon
            fontSize="2rem"
            icon={faCircleExclamation}
            color={COLORS.QuickSilver}
          />
        }
        sx={{ width: '100%', aspectRatio: '1.83' }}
        style={{ objectFit: 'cover', objectPosition: 'center center' }}
      />
    </span>
  </ThumbnailContainer>
);

export default function PhotoGallery({
  plantingDetail,
}: {
  plantingDetail?: SitePlantingDetailDTO | null;
}) {
  const { xs: isMobile } = useBreakpoint();
  const [isFullScreen, onScreenChange] = useState(false);
  const [{ loading, data }, getSitePhotos] = useGetSitePhotos();
  useEffect(() => {
    getSitePhotos({
      urlParams: { addressId: plantingDetail?.addressId },
    });
  }, [plantingDetail?.addressId]);

  const renderItem = (item: ReactImageGalleryItem) => (
    <ImageLoader
      className="image-gallery-image"
      src={item.original}
      failedElement={
        <Skeleton
          variant="rectangular"
          sx={{ width: '100%', height: '100%' }}
        />
      }
      sx={
        isFullScreen
          ? { height: '100dvh' }
          : { aspectRatio: '1.83', padding: '0 0.1rem' }
      }
      style={
        isFullScreen
          ? { height: '100dvh' }
          : { aspectRatio: '1.83', padding: '0 0.1rem' }
      }
    />
  );

  const images = data
    ? _.orderBy(
        data,
        ['isFavorite', (p) => new Date(p.createdDate)],
        ['desc', 'desc'],
      ).map(
        (photo) =>
          ({
            original: photo.url,
            thumbnail: photo.thumbnailUrl || photo.url,
            thumbnailClass: photo.isFavorite && 'favorite',
          } as ReactImageGalleryItem),
      )
    : null;

  if (loading || data == null) {
    return <ImageGalleryLoading />;
  }
  if (data.length === 0) {
    return <ImageGalleryEmpty />;
  }
  return (
    <Gallery>
      <ImageGallery
        items={images || []}
        thumbnailPosition="left"
        lazyLoad={true}
        showBullets
        renderPlayPauseButton={renderPlayPauseButton}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        renderFullscreenButton={renderFullscreenButton}
        renderThumbInner={renderThumbInner}
        renderItem={renderItem}
        onScreenChange={onScreenChange}
        showThumbnails={!isFullScreen}
        useBrowserFullscreen={isMobile ? false : true}
      />
    </Gallery>
  );
}
