import { UserLookupOption } from 'components/admin/user/useUserTableData';
import _ from 'lodash';

export class FilterOptionFormatter {
  static formatOptionsForAutocomplete<T>(
    data: T[],
    formatFunc: (item: T) => { label: string; value: any },
  ) {
    return _.chain(data).map(formatFunc).uniqBy('value').value();
  }
  static formatUserLookup(data: UserLookupOption[]) {
    const optValue = (v: UserLookupOption) => {
      const value = v.fullName.length ? v.fullName : v.email;
      return {
        label: value,
        value,
      };
    };
    return FilterOptionFormatter.formatOptionsForAutocomplete(data, optValue);
  }
}
