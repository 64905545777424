import { useContext } from 'react';
import { MenuItem } from '@mui/material';
import Select from 'components/common/input/select';
import { AppConfigContext } from 'provider/appConfigProvider';
import { Control, Controller } from 'react-hook-form';
import { Placeholder } from 'styled/typography';

export const SubOrgCategorySelect = (props: {
  id: string;
  label: string;
  name: string;
  defaultValue?: number | '';
  orgCategoryId?: number;
  error?: boolean;
  helperText?: string;
  control?: Control<any>;
  placeholder: string;
}) => {
  const {
    id,
    label,
    name,
    defaultValue,
    error,
    helperText,
    control,
    placeholder,
    orgCategoryId,
  } = props;
  const { orgCategories } = useContext(AppConfigContext);
  const selectedCategory =
    !orgCategories || !orgCategoryId
      ? undefined
      : orgCategories?.find((category) => category.id == orgCategoryId);
  const subOrgCategories = selectedCategory?.subCategories;

  const disabled = !subOrgCategories || subOrgCategories.length === 1;

  const renderValue = (value: unknown) => {
    if (disabled) {
      return 'N/A';
    }
    return value ? (
      <>{subOrgCategories?.find((t: any) => t.id === value)?.name}</>
    ) : (
      <Placeholder>{placeholder}</Placeholder>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { ref: selectRef, onBlur, onChange } = field;
        return (
          <Select
            onBlur={onBlur}
            onClose={onBlur}
            onChange={onChange}
            defaultValue={defaultValue || ''}
            inputRef={selectRef}
            id={id}
            label={label}
            error={error}
            helperText={helperText}
            displayEmpty
            renderValue={renderValue}
            disabled={disabled}
          >
            {subOrgCategories?.map((opt: any) => (
              <MenuItem key={opt.id} value={opt.id}>
                {opt.name}
              </MenuItem>
            ))}
          </Select>
        );
      }}
    />
  );
};
