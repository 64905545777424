import { ActionButtonProps } from 'components/auth/signUp/style';
import ModalContent from 'components/common/modalContent';
import _ from 'lodash';
import { ConfirmModalContext, ModalStylesProps } from 'provider/modalProvider';
import { useContext, useCallback, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type ConfirmModalValue = {
  title: string;
  message: string | ReactNode;
  negativeButton?: {
    title?: string;
    action?: () => void;
    variant?: ActionButtonProps['variant'];
  };
  positiveButton: {
    title: string;
    action: () => void;
    variant?: ActionButtonProps['variant'];
    loading?: boolean;
    disabled?: boolean;
  };
  styles?: ModalStylesProps;
  isDialog?: boolean;
};

export default function useConfirmModal() {
  const { showModal, hideModal } = useContext(ConfirmModalContext);
  const [t] = useTranslation();

  const showConfirmModal = useCallback((data: ConfirmModalValue) => {
    const {
      title,
      message,
      negativeButton: {
        title: negativeBtnTitle = t('cancel'),
        action: negativeBtnAction = hideModal,
        variant: negativeBtnVariant = 'outlined',
      } = {},
      positiveButton: {
        title: postiveBtnTitle,
        action: positiveBtnAction,
        variant: positiveBtnVariant = 'contained',
        loading: positiveBtnLoading = false,
        disabled: positiveBtnDisabled = false,
      },
      styles,
      isDialog,
    } = data;

    showModal({
      hideTitle: true,
      isDialog,
      styles: _.merge(
        {
          body: {
            p: 0,
            maxWidth: '42.0625rem',
            '& .MuiStack-root': {
              borderRadius: '10px',
            },
          },
        },
        styles || {},
      ),
      content: (
        <ModalContent
          title={title}
          message={message}
          actions={[
            {
              title: negativeBtnTitle,
              onClick: negativeBtnAction,
              variant: negativeBtnVariant,
            },
            {
              title: postiveBtnTitle,
              onClick: positiveBtnAction,
              variant: positiveBtnVariant,
              loading: positiveBtnLoading,
              disabled: positiveBtnDisabled,
            },
          ]}
        />
      ),
    });
  }, []);

  return {
    showModal: showConfirmModal,
    hideModal,
  };
}
