import { ButtonProps, CircularProgress } from '@mui/material';
import { ComponentType } from 'react';

export default function withLoadingButton<T extends ButtonProps>(
  Button: ComponentType<T>,
) {
  return (props: { loading?: boolean } & T) => {
    const { loading, ...btnProps } = props;
    return (
      <Button
        {...(btnProps as T)}
        startIcon={
          loading ? <CircularProgress size={20} color="inherit" /> : undefined
        }
      />
    );
  };
}
