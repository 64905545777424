export enum OrgAction {
  REQUEST_JOIN = 'REQUEST_JOIN',
  VIEW_MEMBERSHIP = 'VIEW_MEMBERSHIP',
  REQUESTED = 'REQUESTED',
  INVITED = 'INVITED',
  REJECTED = 'REJECTED',
  MANAGE = 'MANAGE',
  INVITE = 'INVITE',
  NO_ACTION = 'NO_ACTION',
}
