import { useAPI } from 'components/common/hook/useApi';
import { Country, MAP_BREAKPOINT_ZOOM_LEVEL } from 'components/map/constants';
import { useMapProviver } from 'components/map/mapProvider';
import { CountryAndLocalitiesInfo } from 'hnpmap-api';
import { useEffect, useMemo } from 'react';
import { useGetUserPlantingInfo } from 'components/common/apis/userAPI';
import { useGetCountryAndLocalitiesInfo } from 'components/common/apis/mapAPI';
import { LngLatLike } from 'react-map-gl';
import {
  CountryBoundaries,
  getCountryBoundaryDatasourceId,
} from 'components/map/mapBoundary';
import _ from 'lodash';
import { useUserInfo } from 'provider/userProvider';

export function useCurrentCountryAndLocalities():
  | CountryAndLocalitiesInfo
  | undefined
  | null {
  const [{ countryInfo: countryData }] = useMapProviver();
  const currentCountry = useGetCurrentCountry();

  const country = useMemo(() => {
    if (currentCountry) {
      return countryData?.[currentCountry];
    }

    return null;
  }, [currentCountry, countryData]);
  return country;
}

export function useGetCurrentLocalityShortCode(): string | undefined {
  const [{ location }] = useMapProviver();

  return location.locality?.shortCode;
}

export function useRankingLayerEnabled(): boolean {
  const [{ viewState }] = useMapProviver();

  return viewState.zoom < MAP_BREAKPOINT_ZOOM_LEVEL;
}

export function useGetCurrentCountry(): Country {
  const { userInfo } = useUserInfo();
  const [{ location }] = useMapProviver();

  return (location?.country?.shortCode ||
    userInfo?.country ||
    Country.US) as Country;
}

export function useGetCountryGeojson(countryCode: string): {
  loading: boolean;
  geojsonData: string | null;
} {
  const [{ countryGeojson }, { setCountryGeojson }] = useMapProviver();
  const geojson = `/mapAssets/country/${countryCode.toLowerCase()}.json`;
  const [{ loading }, fetchGeojson] = useAPI(
    { url: geojson },
    { manual: true, caching: true, cacheTTL: 0, suppressSessionTimeout: true },
  );
  const geojsonData = countryGeojson?.[countryCode];
  useEffect(() => {
    if (!geojsonData && !loading) {
      fetchGeojson()
        .then((res) => {
          if (!res?.data) {
            throw new Error('geojson not found');
          }
          setCountryGeojson(countryCode, res.data);
        })
        .catch(() => setCountryGeojson(countryCode, null));
    }
  }, [geojsonData]);

  return { loading, geojsonData: geojsonData || null };
}

export function useUpdateMapInfoPanelContent() {
  const country = useGetCurrentCountry();
  const { userInfo, setUserInfo } = useUserInfo();
  const getUserPlantingInfo = useGetUserPlantingInfo();
  const getCountryAndLocalitiesInfo = useGetCountryAndLocalitiesInfo();

  const handleSiteUpdated = () => {
    if (userInfo) {
      getUserPlantingInfo().then((data) => {
        if (data) {
          setUserInfo(data);
        }
      });
    }
    getCountryAndLocalitiesInfo(country, true);
  };

  return handleSiteUpdated;
}

export function useSelectLocation() {
  const [{ map }, { setLocation }] = useMapProviver();

  const selectLocationByLngLat = (lngLat: LngLatLike) => {
    const pointInView = map?.project(lngLat);
    const layers = CountryBoundaries.map((c) =>
      getCountryBoundaryDatasourceId(c),
    ).filter((source) => !_.isNil(map?.getSource(source)));

    const locality = _.get(
      map?.queryRenderedFeatures(pointInView as any, {
        layers,
      }),
      [0],
    );

    if (locality) {
      const countryCode = locality.source.split('-')[0];
      setLocation({
        country: { shortCode: countryCode },
        locality: { shortCode: locality.properties?.ABBRIVIATION as string },
        query: null,
      });
    }
  };

  return {
    selectLocationByLngLat,
  };
}
