import {
  faAngleDown,
  faAngleUp,
  faPeopleGroup,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Collapse } from '@mui/material';
import { StyledMenuItem } from 'components/common/menu';
import { useTranslation } from 'react-i18next';
import COLORS from 'styled/colors';
import { ImageLoader } from '../common/org/imageLoader';
import { UserOrgMembershipDTO } from 'hnpmap-api';
import { useState } from 'react';

export const ManageOrgSubMenuItem = ({
  orgId,
  orgLogoUrl,
  orgName,
  openOrgSettings,
}: {
  orgId: number;
  orgLogoUrl?: string | null;
  orgName?: string;
  openOrgSettings: (orgId: number) => void;
}) => {
  const renderLogo = () => {
    return (
      <ImageLoader
        src={orgLogoUrl}
        alt="Organization Logo"
        sx={{ width: '1.25rem', height: '1.25rem' }}
        failedElement={
          <FontAwesomeIcon
            icon={faPeopleGroup}
            fontSize="1rem"
            color={COLORS.Black}
          />
        }
      />
    );
  };

  return (
    <StyledMenuItem
      onClick={() => openOrgSettings?.(orgId)}
      sx={{
        maxWidth: '8.5rem',
        marginLeft: '0.5rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {renderLogo()}
      <Box
        sx={{
          maxWidth: '8.5rem',
          marginLeft: '0.5rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontfamily: 'Roboto',
          fontSize: '0.75rem',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '0.875rem',
          letterSpacing: '-0.0075rem',
        }}
      >
        {orgName}
      </Box>
    </StyledMenuItem>
  );
};

export default function ManageOrgSubMenu({
  userOrganizations,
  openOrgSettings,
}: {
  userOrganizations: UserOrgMembershipDTO[];
  openOrgSettings: (orgId: number) => void;
}) {
  const [t] = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  if (userOrganizations.length === 0) {
    return <Box sx={{ height: 0, width: '226px' }} />;
  }

  const onlyOne = userOrganizations?.length === 1;

  return (
    <>
      <StyledMenuItem
        onClick={
          onlyOne
            ? () => openOrgSettings(userOrganizations[0]?.orgId)
            : handleClick
        }
        icon={faPeopleGroup}
        title={t('Manage Organization')}
        hidden={userOrganizations?.length == 0}
        style={{ height: 0 }}
      >
        {!onlyOne && (
          <Box sx={{ width: '1rem' }}>
            <FontAwesomeIcon
              icon={open ? faAngleUp : faAngleDown}
              fontSize="0.68731rem"
              style={{
                userSelect: 'none',
                display: 'inline-block',
                fill: 'currentcolor',
                fontSize: '1rem',
                marginLeft: '0.75rem',
                marginRight: '-0.75rem',
              }}
            />
          </Box>
        )}
      </StyledMenuItem>
      <Collapse
        in={open}
        timeout="auto"
        sx={{ padding: '0 1rem', maxHeight: '10rem', overflowY: 'auto' }}
      >
        {userOrganizations.map((item) => (
          <ManageOrgSubMenuItem
            key={item.orgId}
            orgId={item.orgId}
            orgLogoUrl={item.logo}
            orgName={item.orgName}
            openOrgSettings={openOrgSettings}
          />
        ))}
      </Collapse>
    </>
  );
}
