export enum OrgRole {
  MEMBER = 'MEMBER',
  ORGANIZATION_MANAGER = 'ORGANIZATION_MANAGER',
  ORGANIZATION_ADMINISTRATOR = 'ORGANIZATION_ADMINISTRATOR',
}

export enum OrgMembershipStatus {
  INVITED = 'INVITED',
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export const RoleFilterValue = {
  ALL: 'value_all',
  MEMBER_ONLY: 'members_only',
  MANAGER_AND_ADMIN_ONLY: 'managers_and_admins_only',
};
