import React from 'react';
import { MenuItem } from '@mui/material';
import Select from 'components/common/input/select';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import { Placeholder } from 'styled/typography';
import { UserOrgMembership } from 'provider/userProvider';
import COLORS from 'styled/colors';
import _ from 'lodash';
import { OrgRole } from 'components/utils/constants';
import { OverflowPopover } from 'components/common/popover';

export const PERSONAL_ADD_SITE_VALUE = -1;
export const SiteOwnerSelect = (props: {
  id: string;
  label: string;
  name: string;
  defaultValue?: number | '';
  error?: boolean;
  helperText?: string;
  control?: Control<any>;
  organizations: UserOrgMembership[];
}) => {
  const {
    id,
    label,
    name,
    defaultValue,
    error,
    helperText,
    control,
    organizations,
  } = props;
  const [t] = useTranslation();

  const renderValue = (value: unknown) => {
    if (value === PERSONAL_ADD_SITE_VALUE) {
      return <>{t('my_personal_account')}</>;
    }
    const selectedOrg = organizations.find((org) => org.orgId === value);

    return selectedOrg ? (
      <>{selectedOrg.orgName}</>
    ) : (
      <Placeholder>{t('select')}</Placeholder>
    );
  };

  const orgManagedByUser = _.chain(organizations)
    .filter((org) =>
      [
        OrgRole.ORGANIZATION_MANAGER,
        OrgRole.ORGANIZATION_ADMINISTRATOR,
      ].includes(org.role),
    )
    .sortBy((org) => org.orgName)
    .value();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { ref: selectRef, onBlur, onChange } = field;

        return (
          <Select
            onBlur={onBlur}
            onClose={onBlur}
            onChange={onChange}
            defaultValue={defaultValue}
            inputRef={selectRef}
            id={id}
            label={label}
            error={error}
            helperText={helperText}
            displayEmpty
            renderValue={renderValue}
            MenuProps={{
              MenuListProps: {
                sx: {
                  py: '1.075rem',
                  '.MuiMenuItem-root': {
                    fontSize: '0.75rem',
                    color: COLORS.Black,
                    lineHeight: '1.875rem',
                    py: 0,
                  },
                },
              },
            }}
          >
            <MenuItem value={PERSONAL_ADD_SITE_VALUE}>
              {t('my_personal_account')}
            </MenuItem>
            {orgManagedByUser?.map((organization) => (
              <MenuItem key={organization.orgId} value={organization.orgId}>
                <OverflowPopover
                  content={organization.orgName}
                  maxWidth="24rem"
                  contentSx={{ lineHeight: '30px' }}
                />
              </MenuItem>
            ))}
          </Select>
        );
      }}
    />
  );
};
