export interface CacheManager {
  get(key: string): any | undefined;
  set(key: string, value: any, ttl?: number): any;
  clear(): void;
}

type CacheValue = { value: any; invalidatedAt?: number };
export class Cache implements CacheManager {
  ttl = 0;
  storage: Map<string, CacheValue>;
  constructor(ttl: number | undefined) {
    this.ttl = ttl || 0;
    this.storage = new Map();
  }
  invalidateCache(key: string) {
    this.storage.delete(key);
  }
  get(key: string): any | undefined {
    const cachedValue = this.storage.get(key);
    if (
      this.ttl > 0 &&
      cachedValue?.invalidatedAt &&
      cachedValue.invalidatedAt <= new Date().getTime()
    ) {
      this.invalidateCache(key);
    }
    return cachedValue?.value;
  }
  set(key: string, value: any): any {
    this.storage.set(key, {
      value,
      invalidatedAt: this.ttl > 0 ? new Date().getTime() + this.ttl : 0,
    });
    return value;
  }
  clear() {
    this.storage.clear();
  }
}
