import { Typography, TypographyProps } from '@mui/material';
import { useLayoutEffect, useMemo, useRef } from 'react';
import { isOverflown, resizeText, setSxProps } from 'styled/utils';

type AutoResizeTextProps = {
  children?: JSX.Element;
  text: string | number | JSX.Element;
  sizeInRem: number;
} & TypographyProps;
const AutoResizeText = ({
  text,
  sizeInRem = 1,
  ...props
}: AutoResizeTextProps) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const fontsize = Number(
    window
      .getComputedStyle(document.documentElement)
      ?.fontSize.replace('px', '') || 16,
  );
  const textSizeInPx = useMemo(
    () => sizeInRem * fontsize,
    [sizeInRem, fontsize],
  );
  useLayoutEffect(() => {
    const elem = textRef.current;
    if (elem) {
      const overflow = isOverflown(elem);
      if (overflow) {
        resizeText({
          element: elem,
          minSize: Math.min(
            textSizeInPx,
            (elem.parentNode as any).clientWidth / String(text).length,
          ),
          maxSize: textSizeInPx,
        });
      }
    }

    return () => {
      if (textRef.current) {
        textRef.current.style.fontSize = textSizeInPx + 'px';
      }
    };
  }, [text, textSizeInPx]);

  return (
    <Typography
      data-testid="auto-resize-text"
      ref={textRef}
      letterSpacing="0"
      {...props}
      sx={[
        {
          whiteSpace: 'nowrap',
          width: '100%',
        },
        ...setSxProps(props.sx),
      ]}
    >
      {text}
    </Typography>
  );
};
export default AutoResizeText;
