import { chain, isEqual } from 'lodash';

export class StringUtils {
  static formatWithPlaceholders = (
    str: string,
    replacements: Record<string, any>,
  ) => {
    return str.replace(
      /{{(\w+)}}/g,
      (placeholderWithDelimiters, placeholderWithoutDelimiters) =>
        replacements.hasOwnProperty(placeholderWithoutDelimiters)
          ? replacements[placeholderWithoutDelimiters]
          : placeholderWithDelimiters,
    );
  };
  static trimAndLowerCase(str: string): string {
    return chain(str).trim().toLower().value();
  }
  static compareTextWithTrimAndIgnoreCase(
    text1: string,
    text2: string,
  ): boolean {
    return isEqual(
      StringUtils.trimAndLowerCase(text1),
      StringUtils.trimAndLowerCase(text2),
    );
  }
}
