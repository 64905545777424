import {
  Box,
  LinearProgress,
  LinearProgressProps,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
  linearProgressClasses,
  styled,
} from '@mui/material';
import COLORS from 'styled/colors';
import { setSxProps } from 'styled/utils';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: '1rem',
  borderRadius: '0.5rem',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: COLORS.LightSilver,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 'inherit',
    backgroundColor: COLORS.TealGreen,
  },
}));

export default function ProgressBar({
  value = 0,
  sx,
  slots,
}: {
  value: number;
  sx?: SxProps<Theme>;
  slots?: { progress: LinearProgressProps; label: TypographyProps };
}) {
  return (
    <Box
      sx={[
        { position: 'relative', height: '16px', width: '100%' },
        ...setSxProps(sx),
      ]}
    >
      <BorderLinearProgress
        variant="determinate"
        value={Math.min(value, 100)}
        {...(slots?.progress || {})}
      >
        test
      </BorderLinearProgress>
      <Typography
        sx={{
          fontSize: '0.75rem',
          lineHeight: '0.875rem',
          fontFamily: 'Roboto',
          fontWeight: 400,
          color: COLORS.White,
          position: 'absolute',
          top: '0',
          left: '50%',
          bottom: 0,
          transform: 'translateX(-50%)',
          textShadow: `1px 1px ${COLORS.Black}`,
        }}
        {...(slots?.label || {})}
      >
        {value}%
      </Typography>
    </Box>
  );
}
