const useAppBarHeight = () => {
  const headerHeight = document
    .querySelector('.MuiAppBar-root')
    ?.getBoundingClientRect().height;

  const pageControlsHeight = document
    .querySelector('#page-controls-bar')
    ?.getBoundingClientRect().height;

  return {
    headerHeight,
    pageControlsHeight,
  };
};

export default useAppBarHeight;
