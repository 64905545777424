import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, IconButtonProps } from '@mui/material';
import COLORS from 'styled/colors';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

export default function FavoriteIcon(props: IconButtonProps) {
  return (
    <IconButton {...props}>
      <FontAwesomeIcon
        fontSize="1em"
        color={COLORS.SunshineYellow}
        stroke={COLORS.Black}
        strokeWidth={'10px'}
        icon={faStar}
      />
    </IconButton>
  );
}
