import { Grid, Skeleton, SxProps, Theme } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';

export const ImageLoader = ({
  style,
  src,
  alt,
  failedElement,
  className,
  sx,
}: {
  src?: string | null;
  style?: CSSProperties;
  alt?: string;
  failedElement: JSX.Element;
  className?: string;
  sx?: SxProps<Theme> | undefined;
}) => {
  const [logoLoadFailed, setLogoLoadFailed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [src]);

  if (src && !logoLoadFailed) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={sx}
      >
        <img
          hidden={loading}
          src={src}
          onError={() => setLogoLoadFailed(true)}
          onLoad={() => setLoading(false)}
          alt={alt}
          style={{ maxHeight: '100%', maxWidth: '100%', ...style }}
          className={className}
        />
        {loading && (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            style={style}
          />
        )}
      </Grid>
    );
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={sx}
    >
      {failedElement}
    </Grid>
  );
};
