import React from 'react';
import { Box, styled } from '@mui/material';
import TextField from 'components/common/input/textfield';
import { useTranslation } from 'react-i18next';
import CollapseComponent from 'components/common/formComponent/collapse';
import { UserSettingFormRegister } from 'components/setting/userSetting';

const PreferencesGoalBoxFormStyle = styled(Box)(() => ({
  padding: '0.5rem 1rem 0.5rem 1rem',
  display: 'flex',
  gap: '1rem',
}));

export const ReferralForm = (
  props: {
    collapseDisabled: boolean;
    rowsNumber?: number;
  } & UserSettingFormRegister,
) => {
  const { register, errors, collapseDisabled, rowsNumber } = props;
  const [t] = useTranslation();
  return (
    <CollapseComponent title={t('referral')} disabled={collapseDisabled}>
      <PreferencesGoalBoxFormStyle>
        <TextField
          id="referral"
          label="Lit up by... If a friend or organization got you digging let us know!"
          {...register('referral')}
          error={!!errors['referral']}
          helperText={errors['referral']?.message?.toString()}
          multiline
          rows={rowsNumber}
          maxRows={4}
        />
      </PreferencesGoalBoxFormStyle>
    </CollapseComponent>
  );
};
