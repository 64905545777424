import { MenuItem } from '@mui/material';
import Select from 'components/common/input/select';
import { ChangeHandler } from 'react-hook-form';
import { useFaqTopicAPI } from 'components/common/apis/guestAPI';
import { Placeholder } from 'styled/typography';

export const TopicSelect = (props: {
  id: string;
  label: string;
  name: string;
  defaultValue?: number;
  onChange: ChangeHandler;
  error?: boolean;
  placeholder: string;
  helperText?: string;
}) => {
  const {
    id,
    label,
    name,
    defaultValue,
    onChange,
    error,
    placeholder,
    helperText,
  } = props;
  const [{ data: topics }] = useFaqTopicAPI();
  return (
    <>
      <Select
        id={id}
        label={label}
        name={name}
        defaultValue={defaultValue}
        onChange={onChange}
        error={error}
        displayEmpty={true}
        renderValue={(value) =>
          value ? (
            <>{topics?.find((t) => t.id === value)?.name}</>
          ) : (
            <Placeholder>{placeholder}</Placeholder>
          )
        }
        helperText={helperText}
      >
        {topics?.map((topic) => (
          <MenuItem key={topic.id} value={topic.id}>
            {topic.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
