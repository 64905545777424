import React, { useContext } from 'react';
import { MenuItem } from '@mui/material';
import Select from 'components/common/input/select';
import { AppConfigContext } from 'provider/appConfigProvider';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import { Placeholder } from 'styled/typography';

export const AreaUnitSelect = (props: {
  id: string;
  label: string;
  name: string;
  defaultValue?: number | '';
  error?: boolean;
  helperText?: string;
  control?: Control<any>;
}) => {
  const { id, label, name, defaultValue, error, helperText, control } = props;

  const [t] = useTranslation();
  const { areaUnits } = useContext(AppConfigContext);

  const renderValue = (value: unknown) => {
    return value ? (
      <>
        {t(
          `area_unit_full_name.${areaUnits?.find((t) => t.id === value)?.name}`,
        )}
      </>
    ) : (
      <Placeholder>{t('select_a_preference')}</Placeholder>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { ref: selectRef, onBlur, onChange } = field;
        return (
          <Select
            onBlur={onBlur}
            onClose={onBlur}
            onChange={onChange}
            defaultValue={defaultValue || ''}
            inputRef={selectRef}
            id={id}
            label={label}
            error={error}
            helperText={helperText}
            displayEmpty
            renderValue={renderValue}
          >
            {areaUnits?.map((opt) => (
              <MenuItem key={opt.id} value={opt.id}>
                {t(`area_unit_full_name.${opt.name}`)}
              </MenuItem>
            ))}
          </Select>
        );
      }}
    />
  );
};
